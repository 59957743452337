<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    width="700"
  >
    <validation-observer
      ref="fieldForm"
      v-slot="{ valid }"
      tag="form"
      @submit.prevent="save"
    >
      <v-card>
        <v-card-title>
          Edit Field
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <component
            :is="form._type | fieldFormComponent"
            v-model="form"
            :exclude-field-names="excludeFieldNames"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            text
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import { db, Timestamp } from '@/plugins/firebase'
export default {
  name: 'Field',
  props: {
    collection: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    error: null,
    form: null,
    mockValue: null,
    goingBack: false
  }),
  computed: {
    ...mapGetters(['project']),
    dialog: {
      get() {
        return this.field
      },
      set(dialog) {
        if (!dialog && !this.goingBack) {
          this.$router.push({ name: 'collection', params: { collectionId: this.collection.id } })
          this.goingBack = true
        }
      }
    },
    field() {
      if (!this.collection) {
        return null
      }
      return this.collection.fields.find(x => x.id === this.$route.params.fieldId)
    },
    excludeFieldNames() {
      return this.collection.fields
        .map(c => c.name)
        .filter(name => name !== this.field.name)
    }
  },
  watch: {
    field: {
      handler(field) {
        if (field) {
          this.form = { ...JSON.parse(JSON.stringify(field)) }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async save() {
      const form = this.$refs.fieldForm
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      const changedKeys = Object.keys(this.form).filter((key) => {
        return !['updatedAt', 'createdAt'].includes(key) && !isEqual(this.form[key], this.field[key])
      })
      if (!changedKeys.length) {
        process.env.NODE_ENV === 'development' && alert('no change')
        this.dialog = false
      }
      this.saving = true
      const fields = [...this.collection.fields]
      fields[fields.indexOf(this.field)] = this.form
      try {
        await db.collection(`projects/${this.project.id}/collections`).doc(this.collection.id).update({
          updatedAt: Timestamp.fromDate(new Date()),
          fields
        })
        this.$store.dispatch('snackbar/new', { message: 'Field saved.' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
        this.error = error
        throw error
      }
      this.saving = false
      this.dialog = false
    }
  }
}
</script>
